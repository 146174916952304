import { Artikel, Blindenzeichen, CreateArtikel } from 'wacoplast_wws__api';
import {
  AUTOMATIC_GENERATION_PLACEHOLDER,
  DialogStrings,
  NUMMER_SORT_OPTS,
  PropsWithServices,
  PropsWithTransaction,
  StammdatenAgGrid,
  StammdatenFieldEditors,
  StartDialogProps,
  formatFlaschenFormNummer,
  formatWerkstoffTypNummer,
  makeForeignKeyColumn,
  makeNumberEditorRenderer,
  makeStammdatenEntityRelatedDisabledEditor,
  makeTextEditorRenderer,
  makeVersionedDropDownPickerRenderer,
  numberValueFormatterGenAGGrid,
  useFetchLikeGetAllServiceFunction,
} from '../../infrastructure';
import { defaultColumnState } from './defaultColumnConfiguration';
import { makeKonkretesBlindenzeichenSelector } from './KonkretesBlindenzeichenSelector';
import { makeWerkstoffFilteredDropDown } from '../werkstoff_mischung_verwalten/WerkstoffFilteredDropDown';


export type ArtikelVerwaltenProps = PropsWithTransaction<PropsWithServices<StartDialogProps>>;

export function ArtikelVerwalten(props: ArtikelVerwaltenProps): JSX.Element {

  const kundenResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllKundeFirmaKundeGet, props.services.firma);
  const empfaengerResult = useFetchLikeGetAllServiceFunction(props.services.firma.getAllFirmaGet, props.services.firma);
  const flaschenFormResult = useFetchLikeGetAllServiceFunction(props.services.flaschenForm.getAllFlaschenFormGet, props.services.flaschenForm);
  const werkstoffTypResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffTyp.getAllWerkstoffTypGet, props.services.werkstoffTyp);
  const werkstoffMischungResult = useFetchLikeGetAllServiceFunction(props.services.werkstoffMischung.getAllWerkstoffMischungGet, props.services.werkstoffMischung);
  const granulatFarbeResult = useFetchLikeGetAllServiceFunction(props.services.granulatFarben.getAllGranulatFarbeGet, props.services.granulatFarben);
  const blindenzeichenResult = useFetchLikeGetAllServiceFunction(props.services.blindenzeichen.getAllBlindenzeichenGet, props.services.blindenzeichen);

  const editors: StammdatenFieldEditors<CreateArtikel> = [
    {
      type: 'simple',
      field: 'nummer',
      renderer: makeTextEditorRenderer(AUTOMATIC_GENERATION_PLACEHOLDER, true),
      label: 'Nummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['kunde_database_id', 'kunde_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(kundenResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
      }, 'kunde', false, (data) => {
        return {
          kunde_database_id: data?.database_id,
          kunde_version_id: data?.version_id,
          empfaenger_database_id: data?.database_id,
          empfaenger_version_id: data?.version_id,
        };
      }),
      label: 'Kunde',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'artikel_nummer_des_kunden',
      renderer: makeTextEditorRenderer(),
      label: 'Kunde-Artikelnummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['empfaenger_database_id', 'empfaenger_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(empfaengerResult, (data) => {
        return { id: data.database_id, primaryTitle: data.name_kurz, secondaryTitle: data.nummer };
      }, 'empfaenger', false),
      label: 'Empfänger',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'artikel_nummer_des_empfaengers',
      renderer: makeTextEditorRenderer(),
      label: 'Empfänger-Artikelnummer',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['flaschen_form_database_id', 'flaschen_form_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(flaschenFormResult, (data) => {
        return { id: data.database_id, primaryTitle: data.flaschentyp.bezeichnung, secondaryTitle: formatFlaschenFormNummer(data) };
      }, 'flaschen_form'),
      label: 'Flaschenform',
      notEditable: true,
    },
    {
      type: 'complex',
      fields: ['blindenzeichen_database_id', 'blindenzeichen_version_id'],
      renderer: makeStammdatenEntityRelatedDisabledEditor<Artikel, Blindenzeichen>(makeKonkretesBlindenzeichenSelector(blindenzeichenResult, flaschenFormResult), (data) => data.flaschen_form === undefined),
      label: 'Blindenzeichen',
      notEditable: true,
    },
    {
      type: 'simple',
      field: 'gewicht',
      renderer: makeNumberEditorRenderer({ precision: 1 }),
      label: 'Gewicht [g]',
    },
    {
      type: 'complex',
      fields: ['werkstoff_mischung_database_id', 'werkstoff_mischung_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(werkstoffMischungResult, (data) => {
        const primaryTitle = `${data.anteil_1}% ${data.werkstoff_1.bezeichnung}${data.werkstoff_2 ? ` - ${data.anteil_2}% ${data.werkstoff_2.bezeichnung}` : ''}`;
        return {
          id: data.database_id,
          primaryTitle: primaryTitle };
      }, 'werkstoff_mischung', false, (data) => {
        return {
          werkstoff_mischung_database_id: data?.database_id,
          werkstoff_mischung_version_id: data?.version_id,
          werkstoff_mischung: data,
          werkstoff_typ_1_database_id: data?.werkstoff_typ_1_database_id,
          werkstoff_typ_1_version_id: data?.werkstoff_typ_1_version_id,
          werkstoff_typ_1: data?.werkstoff_typ_1,
          werkstoff_typ_2_database_id: data?.werkstoff_typ_2_database_id,
          werkstoff_typ_2_version_id: data?.werkstoff_typ_2_version_id,
          werkstoff_typ_2: data?.werkstoff_typ_2,
        };
      }),
      label: 'Werkstoff',
    },
    {
      type: 'complex',
      fields: ['werkstoff_typ_1_database_id', 'werkstoff_typ_1_version_id'],
      renderer: makeStammdatenEntityRelatedDisabledEditor<Artikel, unknown>(makeWerkstoffFilteredDropDown(werkstoffTypResult, (werkstoffTyp) => {
        return { id: werkstoffTyp.database_id, primaryTitle: werkstoffTyp.bezeichnung, secondaryTitle: formatWerkstoffTypNummer(werkstoffTyp.nummer) };
      }, 'werkstoff_typ_1', (artikel: Artikel) => artikel.werkstoff_mischung?.werkstoff_1?.database_id ?? -1, false), (data) => data.werkstoff_mischung === undefined),
      label: 'Werkstofftyp 1',
    },
    {
      type: 'complex',
      fields: ['werkstoff_typ_2_database_id', 'werkstoff_typ_2_version_id'],
      renderer: makeStammdatenEntityRelatedDisabledEditor<Artikel, unknown>(makeWerkstoffFilteredDropDown(werkstoffTypResult, (werkstoffTyp) => {
        return { id: werkstoffTyp.database_id, primaryTitle: werkstoffTyp.bezeichnung, secondaryTitle: formatWerkstoffTypNummer(werkstoffTyp.nummer) };
      }, 'werkstoff_typ_2', (artikel: Artikel) => artikel.werkstoff_mischung?.werkstoff_2?.database_id ?? -1, false), (data) => data.werkstoff_mischung === undefined || data.werkstoff_mischung.werkstoff_2 === undefined),
      label: 'Werkstofftyp 2',
    },
    {
      type: 'complex',
      fields: ['granulat_farbe_database_id', 'granulat_farbe_version_id'],
      renderer: makeVersionedDropDownPickerRenderer(granulatFarbeResult, (data) => {
        return { id: data.database_id, primaryTitle: data.bezeichnung, secondaryTitle: data.nummer };
      }, 'granulat_farbe'),
      label: 'Granulatfarbe',
    },
    {
      type: 'simple',
      field: 'einfaerbungs_grad',
      renderer: makeNumberEditorRenderer({
        precision: 2,
        nullable: true,
      }),
      label: 'Einfärbungsgrad [%]',
    },
  ];

  return (
    <StammdatenAgGrid
      editEditors={editors}
      createEditors={editors}
      defaultColumnState={defaultColumnState}
      columnDefs={[
        {
          headerName: 'Nummer',
          field: 'nummer',
          filter: 'agTextColumnFilter',
          ...NUMMER_SORT_OPTS,
          colId: 'nummer',
        },
        {
          headerName: 'Kunde',
          ...makeForeignKeyColumn('kunde', (data: Artikel) => {
            return {
              description: data.kunde.name_kurz,
              number: data.kunde.nummer,
            };
          }),
          colId: 'kunde',
        },
        {
          headerName: 'Kunden-Artikelnummer',
          field: 'artikel_nummer_des_kunden',
          filter: 'agTextColumnFilter',
          colId: 'artikel_nummer_des_kunden',
        },
        {
          headerName: 'Fl-Typ-Bez',
          colId: 'flaschen_typ_bezeichnung',
          ...makeForeignKeyColumn('flaschen_form', (data: Artikel) => {
            return {
              description: data.flaschen_form.flaschentyp.bezeichnung,
              number: data.flaschen_form.flaschentyp.nummer,
            };
          }),
        },
        {
          headerName: 'Volumen [ml]',
          colId: 'volumen',
          valueGetter: ({ data }) => data.flaschen_form.flaschentyp.volumen,
          valueFormatter: numberValueFormatterGenAGGrid(0),
          filter: 'agNumberColumnFilter',
        },
        {
          headerName: 'Halsteil',
          colId: 'hals_teil',
          ...makeForeignKeyColumn('flaschen_form', (data: Artikel) => {
            return {
              description: data.flaschen_form.hals_teil.bezeichnung,
              number: data.flaschen_form.hals_teil.nummer,
            };
          }),
        },
        {
          headerName: 'Gewicht [g]',
          field: 'gewicht',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          colId: 'gewicht',
        },
        {
          headerName: 'BLZ',
          colId: 'blinden_zeichen',
          ...makeForeignKeyColumn('flaschen_form', (data: Artikel) => {
            return {
              description: data.blindenzeichen.bezeichnung,
              number: data.blindenzeichen.nummer,
            };
          }),
        },
        {
          headerName: 'Farbe',
          ...makeForeignKeyColumn('granulat_farbe', (data: Artikel) => {
            return {
              description: data.granulat_farbe.bezeichnung,
              number: data.granulat_farbe.nummer,
            };
          }),
          colId: 'granulat_farbe',
        },
        {
          headerName: 'Farb-Batch-Nr',
          colId: 'farb_batch_nummer',
          valueGetter: ({ data }) => data.granulat_farbe.batch_nummer,
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Einfärbungsgrad in %',
          field: 'einfaerbungs_grad',
          filter: 'agNumberColumnFilter',
          valueFormatter: numberValueFormatterGenAGGrid(0),
          colId: 'einfaerbungs_grad',
        },
        {
          headerName: 'Werkstoff 1',
          ...makeForeignKeyColumn('werkstoff_mischung', (data: Artikel) => {
            return {
              description: `${data.werkstoff_mischung.anteil_1}% ${data.werkstoff_mischung.werkstoff_1.bezeichnung}`,
              number: data.werkstoff_mischung.werkstoff_1.nummer,
            };
          }),
          colId: 'werkstoff_1',
        },
        {
          headerName: 'WerkstoffTyp 1',
          ...makeForeignKeyColumn('werkstoff_typ_1', (data: Artikel) => {
            return {
              description: data.werkstoff_typ_1.bezeichnung ?? '',
              number: String(data.werkstoff_typ_1.nummer),
            };
          }),
          colId: 'werkstoff_typ_1',
        },
        {
          headerName: 'Werkstoff 2',
          ...makeForeignKeyColumn('werkstoff_mischung', (data: Artikel) => {
            return {
              description: data.werkstoff_mischung.werkstoff_2 ? `${data.werkstoff_mischung.anteil_2}% ${data.werkstoff_mischung.werkstoff_2.bezeichnung}` : '',
              number: data.werkstoff_mischung.werkstoff_2?.nummer,
            };
          }),
          colId: 'werkstoff_2',
        },
        {
          headerName: 'WerkstoffTyp 2',
          ...makeForeignKeyColumn('werkstoff_typ_2', (data: Artikel) => {
            return {
              description: data.werkstoff_typ_2?.bezeichnung ?? '',
              number: String(data.werkstoff_typ_2?.nummer),
            };
          }),
          colId: 'werkstoff_typ_2',
        },
        {
          headerName: 'Empfänger-Firma',
          ...makeForeignKeyColumn('empfaenger', (data: Artikel) => {
            return {
              description: data.empfaenger?.name_kurz ?? '',
              number: data.empfaenger?.nummer,
            };
          }),
          colId: 'empfaenger',
        },
        {
          headerName: 'EmArtNr',
          field: 'artikel_nummer_des_empfaengers',
          filter: 'agTextColumnFilter',
          colId: 'artikel_nummer_des_empfaengers',
        },
      ]}
      stammdatenService={{
        create: (data: CreateArtikel) => props.services.artikel.postArtikelPost({ CreateArtikel: data }),
        update: (data) => props.services.artikel.putByDatabaseIdArtikelDatabaseIdPut(
          {
            database_id: data.database_id,
            UpdateArtikel: data,
          },
        ),
        delete: (data, ignoreIntegrity) => props.services.artikel.deleteByDatabaseIdArtikelDatabaseIdDelete({ database_id: data.database_id, DeleteRequest: { ignore_integrity: ignoreIntegrity, aenderungsgrund: data.aenderungsgrund } }),
        restore: (data) => props.services.artikel.restoreByDatabaseIdArtikelDatabaseIdRestorePatch({ database_id: data.database_id, RestoreRequest: { aenderungsgrund: data.aenderungsgrund } }),
        getAll: (include_deleted) => props.services.artikel.getAllArtikelGet({ include_deleted }),
        getHistory: (artikel) => props.services.artikel.getHistoryArtikelDatabaseIdHistoryGet({ database_id: artikel.database_id }),
        export: () => props.services.artikel.downloadExcelArtikelExcelPost(),
      }}
      transactionService={props.transactionService}
      subscriptionEventService={props.subscriptionEventService}
      onCloseStartDialogClicked={() => props.closeStartDialog()}
      dialogStrings={DialogStrings.Artikel}
    />
  );
}
