/* eslint-disable react/display-name */
import { Dialog } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import {
  BpmnValidationError,
  ProduktionsauftragExternErfassenResult,
  ProduktionsauftragExternErfassenResultFromJSON,
  ProduktionsauftragExternErfassenResultToJSON,
} from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  NullableObject,
  PropsWithServices,
  SidepanelFormCard,
  getPayloadOrBpmnValidationErrorFromToken,
  useFetchLikeGetAllServiceFunction,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { KundenauftragInformationTable, WerkstoffEditors } from '../../..';
import { ProduktionsauftragExternErfassenFields } from '.';

export type ProduktionsauftragExternErfassenProps = PropsWithServices<CustomFormProps<ProduktionsauftragExternErfassenResult & { kundenauftrag_database_id: number }>>;

export function ProduktionsauftragExternErfassen(props: ProduktionsauftragExternErfassenProps): JSX.Element {
  const [
    ,
    previousValues,
    err,
  ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, ProduktionsauftragExternErfassenResultFromJSON);

  const kundenauftrag_database_id = props.tokenPayload.kundenauftrag_database_id;
  const kundenauftrag_service = props.services.kundenauftrag;
  const serviceFunction = useCallback((initOverrides?: RequestInit) => kundenauftrag_service.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: kundenauftrag_database_id }, initOverrides), [kundenauftrag_database_id, kundenauftrag_service]);
  const kundenauftrag = useFetchLikeServiceFunction(serviceFunction, null);

  const werkstoffTypen = useFetchLikeGetAllServiceFunction(props.services.werkstoffTyp.getAllWerkstoffTypGet, props.services.werkstoffTyp);

  const [validationError, _setValidationError] = useState<BpmnValidationError | null>(err);
  const setValidationError = (fields: Array<string>): void => {
    if (validationError) {
      _setValidationError({
        ...validationError,
        detail: validationError?.detail.filter(entry => !entry.loc.some(loc => fields.includes(loc))) ?? [],
      });
    }
  };

  const [produktionsauftragExtern, setProduktionsauftragExtern] = useState<NullableObject<ProduktionsauftragExternErfassenResult>>({
    menge: previousValues.menge ?? kundenauftrag.data?.menge ?? null,
    werkstoff_typ_1: previousValues.werkstoff_typ_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_1 ?? null,
    werkstoff_typ_2: previousValues.werkstoff_typ_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_1 ?? null,
    anteil_werkstoff_1: previousValues.anteil_werkstoff_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_1 ?? null,
    anteil_werkstoff_2: previousValues.anteil_werkstoff_2 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_2 ?? null,
  });

  useEffect(() => {
    setProduktionsauftragExtern({
      ...produktionsauftragExtern,
      menge: produktionsauftragExtern.menge ?? kundenauftrag.data?.menge ?? null,
      werkstoff_typ_1: produktionsauftragExtern.werkstoff_typ_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_1 ?? null,
      werkstoff_typ_2: produktionsauftragExtern.werkstoff_typ_2 ?? kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_2 ?? null,
      anteil_werkstoff_1: produktionsauftragExtern.anteil_werkstoff_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_1 ?? null,
      anteil_werkstoff_2: produktionsauftragExtern.anteil_werkstoff_2 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_2 ?? null,
    });
  }, [kundenauftrag.data]);

  function submit(): Promise<void> {
    const payload: ProduktionsauftragExternErfassenResult = {
      ...produktionsauftragExtern,
    } as any;

    return props.finishUserTask(ProduktionsauftragExternErfassenResultToJSON(payload));
  }

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask(previousValues)}
      title='Produktionsauftrag erfassen'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <SidepanelFormCard
          title='Externen Produktionsauftrag erfassen'
          footer={<></>}
          sidepanel={<KundenauftragInformationTable {...props} kundenauftrag={kundenauftrag} />}
        >
          <WerkstoffEditors
            kundenauftrag={kundenauftrag}
            werkstoff_typen={werkstoffTypen}
            validationError={validationError}
            onChange={(obj) => {
              setProduktionsauftragExtern({ ...produktionsauftragExtern, ...obj });
              setValidationError(Object.keys(obj));
            }}
            werkstoffData={{
              anteil_werkstoff_1: produktionsauftragExtern.anteil_werkstoff_1,
              anteil_werkstoff_2: produktionsauftragExtern.anteil_werkstoff_2 ?? null,
              werkstoffTyp1: produktionsauftragExtern.werkstoff_typ_1,
              werkstoffTyp2: produktionsauftragExtern.werkstoff_typ_2 ?? null,
            }}
          />
          <ProduktionsauftragExternErfassenFields
            menge={produktionsauftragExtern.menge}
            validationError={validationError}
            onChange={(obj) => {
              setProduktionsauftragExtern({ ...produktionsauftragExtern, ...obj });
              setValidationError(Object.keys(obj));
            }}
          />
        </SidepanelFormCard>
      </DialogBody>
      <DialogFooter
        onFinish={submit}
        onAbort={() => props.abortUserTask(previousValues)}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
