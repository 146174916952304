/* eslint-disable react/display-name */
import { Button, Dialog, H5 } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import {
  Abwicklungspfad,
  Blasmaschine,
  Kundenauftrag,
  Lagerposten,
  ProduktionsauftragBlasenErfassenResult,
  ProduktionsauftragErfassenResult,
  ProduktionsauftragErfassenResultToJSON,
  ProduktionsauftragExternErfassenResult,
  ProduktionsauftragLagerErfassenResult,
  WerkstoffTyp,
} from 'wacoplast_wws__api';
import { AbwicklungspfadKeyMapping, LagerpostenDialog, hasAbwicklungspfad } from '../..';
import {
  KundenauftragInformationTable,
  ProduktionsauftragBlasenErfassenFields,
  ProduktionsauftragExternErfassenFields,
  ProduktionsauftragLagerErfassenFields,
} from '../../..';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  DialogFormField,
  EnumDropDownPicker,
  NullableObject,
  PropsWithServices,
  SidepanelFormCard,
  UseFetchLikeServiceFunctionResult,
  useFetchLikeGetAllServiceFunction,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import styles from './ProduktionsauftragErfassen.module.scss';
import { WerkstoffEditors } from './WerkstoffEditors';

export type ProduktionsauftragErfassenProps = PropsWithServices<CustomFormProps<ProduktionsauftragErfassenResult & { kundenauftrag_database_id: number, lager_entries: Array<Lagerposten> }>>;
export type ProduktionsauftragErfassenWerkstoffData = {
  werkstoffTyp1: WerkstoffTyp | null;
  werkstoffTyp2: WerkstoffTyp | null;
  anteil_werkstoff_1: number | null;
  anteil_werkstoff_2: number | null;
};

type ProduktionsauftragErfassenCardProps = ProduktionsauftragErfassenProps & {
  kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag>;
  blasmaschinen: UseFetchLikeServiceFunctionResult<Array<Blasmaschine>>;
  lagerposten: UseFetchLikeServiceFunctionResult<Array<Lagerposten>>;
  werkstoff_typen: UseFetchLikeServiceFunctionResult<Array<WerkstoffTyp>>;
};

function ProduktionsauftragErfassenCard(props: ProduktionsauftragErfassenCardProps): JSX.Element {

  const [abwicklungspfad, setAbwicklungspfad] = useState<Abwicklungspfad>(Abwicklungspfad.NUMBER_3);
  const [produktionsauftragLager, setProduktionsauftragLager] = useState<NullableObject<ProduktionsauftragLagerErfassenResult>>({
    menge: props.kundenauftrag.data?.menge ?? null,
    werkstoff_typ_1: null,
    werkstoff_typ_2: null,
    anteil_werkstoff_1: null,
    anteil_werkstoff_2: null,
  });
  const [produktionsauftragExtern, setProduktionsauftragExtern] = useState<NullableObject<ProduktionsauftragExternErfassenResult>>({
    menge: props.kundenauftrag.data?.menge ?? null,
    werkstoff_typ_1: null,
    werkstoff_typ_2: null,
    anteil_werkstoff_1: null,
    anteil_werkstoff_2: null,
  });
  const [produktionsauftragBlasen, setProduktionsauftragBlasen] = useState<NullableObject<ProduktionsauftragBlasenErfassenResult>>({
    blasdauer: null,
    blasmaschine: null,
    fertig_vor_liefertermin: null,
    menge: props.kundenauftrag.data?.menge ?? null,
    vorgegebene_blasmaschine: null,
    werkstoff_typ_1: null,
    werkstoff_typ_2: null,
    anteil_werkstoff_1: null,
    anteil_werkstoff_2: null,
  });
  const [werkstoffData, setWerkstoffData] = useState<ProduktionsauftragErfassenWerkstoffData>({
    werkstoffTyp1: props.kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_1 ?? null,
    werkstoffTyp2: props.kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_2 ?? null,
    anteil_werkstoff_1: props.kundenauftrag.data?.artikel.werkstoff_mischung.anteil_1 ?? null,
    anteil_werkstoff_2: props.kundenauftrag.data?.artikel.werkstoff_mischung.anteil_2 ?? null,
  });
  const [showLagerDialog, setShowLagerDialog] = useState<boolean>(false);

  useEffect(() => {
    setProduktionsauftragLager({
      ...produktionsauftragLager,
      menge: produktionsauftragLager.menge ?? props.kundenauftrag.data?.menge ?? null,
    });
    setProduktionsauftragExtern({
      ...produktionsauftragExtern,
      menge: produktionsauftragExtern.menge ?? props.kundenauftrag.data?.menge ?? null,
    });
    setProduktionsauftragBlasen({
      ...produktionsauftragBlasen,
      menge: produktionsauftragBlasen.menge ?? props.kundenauftrag.data?.menge ?? null,
    });
    setWerkstoffData({
      werkstoffTyp1: werkstoffData.werkstoffTyp1 ?? props.kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_1 ?? null,
      werkstoffTyp2: werkstoffData.werkstoffTyp2 ?? props.kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_2 ?? null,
      anteil_werkstoff_1: werkstoffData.anteil_werkstoff_1 ?? props.kundenauftrag.data?.artikel.werkstoff_mischung.anteil_1 ?? null,
      anteil_werkstoff_2: werkstoffData.anteil_werkstoff_2 ?? props.kundenauftrag.data?.artikel.werkstoff_mischung.anteil_2 ?? null,
    });
  }, [props.kundenauftrag.data]);

  async function submit(): Promise<void> {
    if (abwicklungspfad === null) {
      return;
    }
    const produktionsauftrag_lager = {
      ...produktionsauftragLager,
      werkstoff_typ_1: werkstoffData.werkstoffTyp1,
      werkstoff_typ_2: werkstoffData.werkstoffTyp2,
      anteil_werkstoff_1: werkstoffData.anteil_werkstoff_1,
      anteil_werkstoff_2: werkstoffData.anteil_werkstoff_2,
    };

    const produktionsauftrag_extern = {
      ...produktionsauftragExtern,
      werkstoff_typ_1: werkstoffData.werkstoffTyp1,
      werkstoff_typ_2: werkstoffData.werkstoffTyp2,
      anteil_werkstoff_1: werkstoffData.anteil_werkstoff_1,
      anteil_werkstoff_2: werkstoffData.anteil_werkstoff_2,
    };

    const produktionsauftrag_blasen = {
      ...produktionsauftragBlasen,
      werkstoff_typ_1: werkstoffData.werkstoffTyp1,
      werkstoff_typ_2: werkstoffData.werkstoffTyp2,
      anteil_werkstoff_1: werkstoffData.anteil_werkstoff_1,
      anteil_werkstoff_2: werkstoffData.anteil_werkstoff_2,
    };

    const payload: ProduktionsauftragErfassenResult = {
      abwicklungspfad: abwicklungspfad,
      produktionsauftrag_lager: hasAbwicklungspfad(abwicklungspfad, Abwicklungspfad.NUMBER_1) ? produktionsauftrag_lager : undefined,
      produktionsauftrag_blasen: hasAbwicklungspfad(abwicklungspfad, Abwicklungspfad.NUMBER_3) ? produktionsauftrag_blasen : undefined,
      produktionsauftrag_extern: hasAbwicklungspfad(abwicklungspfad, Abwicklungspfad.NUMBER_5) ? produktionsauftrag_extern : undefined,
    } as any;

    return props.finishUserTask(ProduktionsauftragErfassenResultToJSON(payload));
  }

  function renderProduktionsauftragBlasenErfassenFields(): JSX.Element {
    return (
      <>
        <H5>Blas-Abwicklung</H5>
        <ProduktionsauftragBlasenErfassenFields
          blasmaschinen={props.blasmaschinen}
          {...produktionsauftragBlasen}
          gewicht={props.kundenauftrag.data?.artikel.gewicht}
          einfaerbungs_grad={props.kundenauftrag.data?.artikel.einfaerbungs_grad}
          vorgegebene_blasmaschine={produktionsauftragBlasen.vorgegebene_blasmaschine ?? null}
          onChange={(obj) => setProduktionsauftragBlasen({ ...produktionsauftragBlasen, ...obj })}
        />
      </>
    );
  }

  function renderProduktionsauftragLagerErfassenFields(): JSX.Element {
    return (
      <>
        <H5>Lager-Abwicklung</H5>
        <ProduktionsauftragLagerErfassenFields
          {...produktionsauftragLager}
          onChange={(obj) => setProduktionsauftragLager({ ...produktionsauftragLager, ...obj })}
        />
      </>
    );
  }

  function renderProduktionsauftragExternErfassenFields(): JSX.Element {
    return (
      <>
        <H5>Extern-Abwicklung</H5>
        <ProduktionsauftragExternErfassenFields
          {...produktionsauftragExtern}
          onChange={(obj) => setProduktionsauftragExtern({ ...produktionsauftragExtern, ...obj })}
        />
      </>
    );
  }

  function renderVorhandeneLagerpostenAnzeigen(): JSX.Element {
    return (
      <Button
        className={styles.lagerpostenButton}
        fill
        onClick={() => setShowLagerDialog(true)}
        disabled={props.lagerposten.data === null || props.lagerposten.data?.length === 0}
      >
        Vorhandene Lagerposten anzeigen ({props.lagerposten.data?.length ?? 'Lade...'})
      </Button>
    );
  }

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask()}
      title='Produktionsauftrag erfassen'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <SidepanelFormCard
          title='Abwicklungspfad wählen'
          footer={<></>}
          sidepanel={<KundenauftragInformationTable {...props} kundenauftrag={props.kundenauftrag} />}
        >
          <LagerpostenDialog
            isOpen={showLagerDialog}
            onClose={() => setShowLagerDialog(false)}
            data={props.lagerposten.data ?? []}
          />
          {renderVorhandeneLagerpostenAnzeigen()}
          <WerkstoffEditors
            kundenauftrag={props.kundenauftrag}
            onChange={(obj) => setWerkstoffData({ ...werkstoffData, ...obj })}
            validationError={null}
            werkstoffData={werkstoffData}
            werkstoff_typen={props.werkstoff_typen}
          />
          <DialogFormField
            fieldLocation={['abwicklungspfad']}
            fieldLabel='Abwicklungspfad'
          >
            <EnumDropDownPicker
              data={Object.values(Abwicklungspfad)}
              getDisplayData={(data) => (AbwicklungspfadKeyMapping as any)[data]?.toString()}
              onChange={(value) => setAbwicklungspfad((typeof value === 'string' ? null : value) ?? Abwicklungspfad.NUMBER_3)}
              value={abwicklungspfad}
            />
          </DialogFormField>

          {hasAbwicklungspfad(abwicklungspfad, Abwicklungspfad.NUMBER_3) && renderProduktionsauftragBlasenErfassenFields()}
          {hasAbwicklungspfad(abwicklungspfad, Abwicklungspfad.NUMBER_1) && renderProduktionsauftragLagerErfassenFields()}
          {hasAbwicklungspfad(abwicklungspfad, Abwicklungspfad.NUMBER_5) && renderProduktionsauftragExternErfassenFields()}

        </SidepanelFormCard>
      </DialogBody>
      <DialogFooter
        onFinish={submit}
        onAbort={props.abortUserTask}
        disabled={props.disabled}
      />
    </Dialog>
  );
}

export function ProduktionsauftragErfassen(props: ProduktionsauftragErfassenProps): JSX.Element {
  const blasmaschinen = useFetchLikeGetAllServiceFunction(props.services.blasmaschine.getAllStellblattBlasmaschineGet, props.services.blasmaschine);

  const kundenauftrag_database_id = props.tokenPayload.kundenauftrag_database_id;
  const kundenauftrag_service = props.services.kundenauftrag;
  const kundenauftragServiceFunction = useCallback((initOverrides?: RequestInit) => kundenauftrag_service.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: kundenauftrag_database_id }, initOverrides), [kundenauftrag_database_id, kundenauftrag_service]);
  const kundenauftrag = useFetchLikeServiceFunction(kundenauftragServiceFunction, null);

  const lagerpostenServiceFunction = useCallback((initOverrides?: RequestInit) => props.services.lagerposten.getAllKundeLagerpostenSimilarFlaschenFlaschentypBezeichnungGewichtGranulatFarbeBezeichnungGet({
    flaschentyp_bezeichnung: kundenauftrag.data?.artikel.flaschen_form.flaschentyp.bezeichnung ?? 'null',
    gewicht: kundenauftrag.data?.artikel.gewicht ?? 0,
    granulat_farbe_bezeichnung: kundenauftrag.data?.artikel.granulat_farbe.bezeichnung ?? 'null',
  }, initOverrides), [kundenauftrag.data, props.services.lagerposten]);
  const lagerposten = useFetchLikeServiceFunction(lagerpostenServiceFunction, null);

  const werkstoffTypen = useFetchLikeGetAllServiceFunction(props.services.werkstoffTyp.getAllWerkstoffTypGet, props.services.werkstoffTyp);

  return (
    <ProduktionsauftragErfassenCard
      {...props}
      kundenauftrag={kundenauftrag}
      blasmaschinen={blasmaschinen}
      lagerposten={lagerposten}
      werkstoff_typen={werkstoffTypen}
    />
  );
}
