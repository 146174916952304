"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Wacoplast WWS API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProduktionsauftragApi = void 0;
const runtime = require("../runtime");
const models_1 = require("../models");
/**
 *
 */
class ProduktionsauftragApi extends runtime.BaseAPI {
    /**
     * Download Excel
     */
    downloadExcelProduktionsauftragExcelPostRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/produktionsauftrag/excel`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.BlobApiResponse(response);
        });
    }
    /**
     * Download Excel
     */
    downloadExcelProduktionsauftragExcelPost(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.downloadExcelProduktionsauftragExcelPostRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get All
     */
    getAllProduktionsauftragGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            if (requestParameters.include_deleted !== undefined) {
                queryParameters['include_deleted'] = requestParameters.include_deleted;
            }
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/produktionsauftrag/`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(models_1.ProduktionsauftragFromJSON));
        });
    }
    /**
     * Get All
     */
    getAllProduktionsauftragGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getAllProduktionsauftragGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get Blasen By Database Id
     */
    getBlasenByDatabaseIdProduktionsauftragBlasenDatabaseIdGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling getBlasenByDatabaseIdProduktionsauftragBlasenDatabaseIdGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/produktionsauftrag/blasen/{database_id}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragBlasenFromJSON(jsonValue));
        });
    }
    /**
     * Get Blasen By Database Id
     */
    getBlasenByDatabaseIdProduktionsauftragBlasenDatabaseIdGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getBlasenByDatabaseIdProduktionsauftragBlasenDatabaseIdGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Get By Pau Nummer
     */
    getByPauNummerProduktionsauftragPauNummerGetRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.pau_nummer === null || requestParameters.pau_nummer === undefined) {
                throw new runtime.RequiredError('pau_nummer', 'Required parameter requestParameters.pau_nummer was null or undefined when calling getByPauNummerProduktionsauftragPauNummerGet.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/produktionsauftrag/{pau_nummer}`.replace(`{${"pau_nummer"}}`, encodeURIComponent(String(requestParameters.pau_nummer))),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragFromJSON(jsonValue));
        });
    }
    /**
     * Get By Pau Nummer
     */
    getByPauNummerProduktionsauftragPauNummerGet(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getByPauNummerProduktionsauftragPauNummerGetRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Update Menge
     */
    updateMengeProduktionsauftragDatabaseIdMengeMengePatchRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters.database_id === null || requestParameters.database_id === undefined) {
                throw new runtime.RequiredError('database_id', 'Required parameter requestParameters.database_id was null or undefined when calling updateMengeProduktionsauftragDatabaseIdMengeMengePatch.');
            }
            if (requestParameters.menge === null || requestParameters.menge === undefined) {
                throw new runtime.RequiredError('menge', 'Required parameter requestParameters.menge was null or undefined when calling updateMengeProduktionsauftragDatabaseIdMengeMengePatch.');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("HTTPBearer", []);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/produktionsauftrag/{database_id}/menge/{menge}`.replace(`{${"database_id"}}`, encodeURIComponent(String(requestParameters.database_id))).replace(`{${"menge"}}`, encodeURIComponent(String(requestParameters.menge))),
                method: 'PATCH',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => models_1.ProduktionsauftragFromJSON(jsonValue));
        });
    }
    /**
     * Update Menge
     */
    updateMengeProduktionsauftragDatabaseIdMengeMengePatch(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.updateMengeProduktionsauftragDatabaseIdMengeMengePatchRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
exports.ProduktionsauftragApi = ProduktionsauftragApi;
