/* eslint-disable react/display-name */
import { Dialog } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';
import {
  BpmnValidationError,
  ProduktionsauftragBlasenErfassenResult,
  ProduktionsauftragBlasenErfassenResultFromJSON,
  ProduktionsauftragBlasenErfassenResultToJSON,
} from 'wacoplast_wws__api';
import {
  CustomFormProps,
  DialogBody,
  DialogFooter,
  NullableObject,
  PropsWithServices,
  SidepanelFormCard,
  getPayloadOrBpmnValidationErrorFromToken,
  useFetchLikeGetAllServiceFunction,
  useFetchLikeServiceFunction,
} from '../../../../infrastructure';
import { KundenauftragInformationTable, WerkstoffEditors } from '../../..';
import { ProduktionsauftragBlasenErfassenFields } from '..';

export type ProduktionsauftragBlasenErfassenProps = PropsWithServices<CustomFormProps<ProduktionsauftragBlasenErfassenResult & { kundenauftrag_database_id: number }>>;

export function ProduktionsauftragBlasenErfassen(props: ProduktionsauftragBlasenErfassenProps): JSX.Element{

  const [
    ,
    previousValues,
    err,
  ] = getPayloadOrBpmnValidationErrorFromToken(props.tokenPayload, ProduktionsauftragBlasenErfassenResultFromJSON);

  const blasmaschinen = useFetchLikeGetAllServiceFunction(props.services.blasmaschine.getAllStellblattBlasmaschineGet, props.services.blasmaschine);

  const kundenauftrag_database_id = props.tokenPayload.kundenauftrag_database_id;
  const kundenauftrag_service = props.services.kundenauftrag;
  const serviceFunction = useCallback((initOverrides?: RequestInit) => kundenauftrag_service.getByDatabaseIdKundenauftragDatabaseIdGet({ database_id: kundenauftrag_database_id }, initOverrides), [kundenauftrag_database_id, kundenauftrag_service]);
  const kundenauftrag = useFetchLikeServiceFunction(serviceFunction, null);

  const werkstoffTypen = useFetchLikeGetAllServiceFunction(props.services.werkstoffTyp.getAllWerkstoffTypGet, props.services.werkstoffTyp);

  const [validationError, _setValidationError] = useState<BpmnValidationError | null>(err);
  const setValidationError = (fields: Array<string>): void => {
    if (validationError) {
      _setValidationError({
        ...validationError,
        detail: validationError?.detail.filter(entry => !entry.loc.some(loc => fields.includes(loc))) ?? [],
      });
    }
  };

  const [produktionsauftragBlasen, setProduktionsauftragBlasen] = useState<NullableObject<ProduktionsauftragBlasenErfassenResult>>({
    blasdauer: previousValues.blasdauer ?? null,
    blasmaschine: previousValues.blasmaschine ?? null,
    fertig_vor_liefertermin: previousValues.fertig_vor_liefertermin ?? null,
    menge: previousValues.menge ?? kundenauftrag.data?.menge ?? null,
    vorgegebene_blasmaschine: previousValues.vorgegebene_blasmaschine ?? null,
    werkstoff_typ_1: previousValues.werkstoff_typ_1 ?? kundenauftrag.data?.artikel.werkstoff_typ_1 ?? null,
    werkstoff_typ_2: previousValues.werkstoff_typ_2 ?? kundenauftrag.data?.artikel.werkstoff_typ_2 ?? null,
    anteil_werkstoff_1: previousValues.anteil_werkstoff_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_1 ?? null,
    anteil_werkstoff_2: previousValues.anteil_werkstoff_2 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_2 ?? null,
  });

  useEffect(() => {
    setProduktionsauftragBlasen({
      ...produktionsauftragBlasen,
      menge: produktionsauftragBlasen.menge ?? kundenauftrag.data?.menge ?? null,
      werkstoff_typ_1: produktionsauftragBlasen.werkstoff_typ_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_1 ?? null,
      werkstoff_typ_2: produktionsauftragBlasen.werkstoff_typ_2 ?? kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_typ_2 ?? null,
      anteil_werkstoff_1: produktionsauftragBlasen.anteil_werkstoff_1 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_1 ?? null,
      anteil_werkstoff_2: produktionsauftragBlasen.anteil_werkstoff_2 ?? kundenauftrag.data?.artikel.werkstoff_mischung.anteil_2 ?? null,
    });
  }, [kundenauftrag.data]);

  function submit (): Promise<void> {

    const payload: ProduktionsauftragBlasenErfassenResult = {
      ...produktionsauftragBlasen,
    } as any;

    return props.finishUserTask(ProduktionsauftragBlasenErfassenResultToJSON(payload));
  }

  return (
    <Dialog
      isOpen={true}
      onClose={() => props.abortUserTask(previousValues)}
      title='Produktionsauftrag erfassen'
      isCloseButtonShown={!props.disabled}
    >
      <DialogBody>
        <SidepanelFormCard
          title='Blasen-Produktionsauftrag erfassen'
          footer={<></>}
          sidepanel={<KundenauftragInformationTable {...props} kundenauftrag={kundenauftrag} />}
        >
          <WerkstoffEditors
            kundenauftrag={kundenauftrag}
            werkstoff_typen={werkstoffTypen}
            validationError={validationError}
            onChange={(obj) => {
              setProduktionsauftragBlasen({ ...produktionsauftragBlasen, ...obj });
              setValidationError(Object.keys(obj));
            }}
            werkstoffData={{
              anteil_werkstoff_1: produktionsauftragBlasen.anteil_werkstoff_1,
              anteil_werkstoff_2: produktionsauftragBlasen.anteil_werkstoff_2 ?? null,
              werkstoffTyp1: produktionsauftragBlasen.werkstoff_typ_1,
              werkstoffTyp2: produktionsauftragBlasen.werkstoff_typ_2 ?? null,
            }}
          />
          <ProduktionsauftragBlasenErfassenFields
            {...produktionsauftragBlasen}
            vorgegebene_blasmaschine={produktionsauftragBlasen.vorgegebene_blasmaschine ?? null}
            blasmaschinen={blasmaschinen}
            gewicht={kundenauftrag.data?.artikel.gewicht}
            einfaerbungs_grad={kundenauftrag.data?.artikel.einfaerbungs_grad}
            validationError={validationError}
            onChange={(obj) => {
              setProduktionsauftragBlasen({
                ...produktionsauftragBlasen,
                ...obj as any,
              });
              setValidationError(Object.keys(obj));
            }}
          />
        </SidepanelFormCard>
      </DialogBody>
      <DialogFooter
        onFinish={submit}
        onAbort={() => props.abortUserTask(previousValues)}
        disabled={props.disabled}
      />
    </Dialog>
  );
}
