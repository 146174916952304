import { BpmnValidationError, Kundenauftrag, WerkstoffTyp } from 'wacoplast_wws__api';
import {
  DefaultDropDownPicker,
  DialogFormField,
  NumberEditor,
  TextViewer,
  UseFetchLikeServiceFunctionResult,
} from '../../../../infrastructure';
import { ProduktionsauftragErfassenWerkstoffData } from './ProduktionsauftragErfassen';

export type WerkstoffEditorsProps = {
  validationError: BpmnValidationError | null;
  werkstoff_typen: UseFetchLikeServiceFunctionResult<WerkstoffTyp[]>;
  kundenauftrag: UseFetchLikeServiceFunctionResult<Kundenauftrag>;
  onChange: (werkstoffData: Partial<ProduktionsauftragErfassenWerkstoffData>) => void;
  werkstoffData: ProduktionsauftragErfassenWerkstoffData;
};

export function WerkstoffEditors(props: WerkstoffEditorsProps): JSX.Element {
  const werkstoffTyp1DataProvider: UseFetchLikeServiceFunctionResult<WerkstoffTyp[]> = {
    ...props.werkstoff_typen,
    data: props.werkstoff_typen.data?.filter(werkstoff_typ =>
      werkstoff_typ.werkstoff_database_id === undefined
      || werkstoff_typ.werkstoff_database_id === props.kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_1_database_id,
    ) as any ?? props.werkstoff_typen.data,
  };
  const werkstoffTyp2DataProvider: UseFetchLikeServiceFunctionResult<WerkstoffTyp[]> = {
    ...props.werkstoff_typen,
    data: props.werkstoff_typen.data?.filter(werkstoff_typ =>
      werkstoff_typ.werkstoff_database_id === undefined
      || werkstoff_typ.werkstoff_database_id === props.kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_2_database_id,
    ) as any ?? props.werkstoff_typen.data,
  };
  const auftragHasTwoWerkstoffs = props.kundenauftrag.data?.artikel.werkstoff_mischung.werkstoff_2_database_id !== undefined;

  return (
    <>
      <DialogFormField
        lastValidationResponse={props.validationError}
        fieldLocation={['werkstoff_typ_1_database_id']}
        fieldLabel='Werkstofftyp 1'
      >
        <DefaultDropDownPicker
          dataProvider={werkstoffTyp1DataProvider}
          getDisplayData={(werkstoff_typ) => ({ primaryTitle: werkstoff_typ.bezeichnung, secondaryTitle: String(werkstoff_typ.nummer) })}
          onChange={(data) => props.onChange({ werkstoffTyp1: data })}
          value={props.werkstoffData.werkstoffTyp1}
        />
      </DialogFormField>
      {auftragHasTwoWerkstoffs && (
        <>
          <DialogFormField
            lastValidationResponse={props.validationError}
            fieldLocation={['werkstoff_typ_2_database_id']}
            fieldLabel='Werkstofftyp 2'
          >
            <DefaultDropDownPicker
              dataProvider={werkstoffTyp2DataProvider}
              getDisplayData={(werkstoff_typ) => ({ primaryTitle: werkstoff_typ.bezeichnung, secondaryTitle: String(werkstoff_typ.nummer) })}
              onChange={(data) => props.onChange({ werkstoffTyp2: data })}
              value={props.werkstoffData.werkstoffTyp2}
            />
          </DialogFormField>
          <DialogFormField
            lastValidationResponse={props.validationError}
            fieldLocation={['werkstoff1_anteil']}
            fieldLabel='Werkstoff 1 Anteil'
          >
            <NumberEditor min={1} max={99} onChange={(value) => props.onChange({ anteil_werkstoff_1: value ?? 0, anteil_werkstoff_2: 100 - (value ?? 0) })} value={props.werkstoffData.anteil_werkstoff_1} />
          </DialogFormField>
          <DialogFormField
            lastValidationResponse={props.validationError}
            fieldLocation={['werkstoff2_anteil']}
            fieldLabel='Werkstoff 2 Anteil'
          >
            <TextViewer value={props.werkstoffData.anteil_werkstoff_2?.toString() ?? null} />
          </DialogFormField>
        </>
      )}
    </>
  );
}
