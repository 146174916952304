import {
  DialogBody,
  DialogFooter,
  ModalProps,
  PropsWithServices,
} from '../../infrastructure';
import { Button, Dialog } from '@blueprintjs/core';

export type StornierenCurrentlyImpossibleModalProps = PropsWithServices<ModalProps<boolean>>;

export function StornierenCurrentlyImpossibleModal(props: StornierenCurrentlyImpossibleModalProps): JSX.Element {
  return (
    <Dialog isOpen onClose={() => props.onModalFinished(true)} title='Stornieren nicht möglich'>
      <DialogBody>
        Bitte zuerst die zugehörigen Produktionsaufträge stornieren.
      </DialogBody>
      <DialogFooter>
        <Button intent='primary' onClick={() => props.onModalFinished(true)}>Ok</Button>
      </DialogFooter>
    </Dialog>
  );
}
