import { Button, Dialog } from '@blueprintjs/core';

import {
  AuthProviderContext,
  DialogBody,
  DialogFooter,
  DialogFormField,
  DialogHeader,
  ModalProps,
  NumberEditor,
  PropsWithServices,
} from '../../infrastructure';
import React from 'react';
import { MaschinenlaufplanModal } from '../../auftragsabwicklung/plantafel/modals';
import { AbwicklungspfadProduktionsauftrag, HTTPValidationError, ProduktionsauftragStatus } from 'wacoplast_wws__api';
import { ProcessConstants } from '../../processes';

type StellblattNachdruckenModalProps = PropsWithServices<ModalProps<void>>;

export function StellblattNachdruckenModal(props: StellblattNachdruckenModalProps): JSX.Element {

  const [pauNummer, setPauNummer] = React.useState<number | null>(null);
  const [nummerEntered, setNummerEntered] = React.useState(false);
  const [correlationId, setCorrelationId] = React.useState<string | null>(null);
  const authProvider = React.useContext(AuthProviderContext).authProvider;
  const [validationError, setValidationError] = React.useState<HTTPValidationError | null>(null);

  if (nummerEntered) {
    return (
      <MaschinenlaufplanModal
        onModalFinished={() => {
          props.onModalFinished();
        }}
        atlasEngineClient={props.atlasEngineClient}
        subscriptionEventService={props.subscriptionEventService}
        services={props.services}
        getIdentity={() => authProvider.getIdentity()!}
        config={props.config}
        correlation_id={correlationId!}
      />
    );
  }

  return (
    <Dialog isOpen={true} onClose={() => props.onModalFinished()}>
      <DialogHeader
        onClick={() => props.onModalFinished()}
      >
        Stellblatt nachdrucken
      </DialogHeader>
      <DialogBody>
        <DialogFormField
          fieldLabel={'PAU Nummer'}
          fieldLocation={['pau_nummer']}
          lastValidationResponse={validationError}
        >
          <NumberEditor
            onChange={(value) =>setPauNummer(value)}
            value={pauNummer}
          />
        </DialogFormField>
      </DialogBody>
      <DialogFooter>
        <Button
          intent='primary'
          onClick={async () =>{
            try {
              const pau = await props.services.produktionsauftrag.getByPauNummerProduktionsauftragPauNummerGet({ pau_nummer: pauNummer! });
              if (pau.abwicklungspfad !== AbwicklungspfadProduktionsauftrag.blasen || pau.status !== ProduktionsauftragStatus.NUMBER_6) {
                setValidationError({
                  detail: [
                    {
                      loc: ['pau_nummer'],
                      msg: 'PAU muss sich im Blasen Abwicklungspfad befinden und den Status "fertig" haben',
                      type: 'value_error',
                    },
                  ],
                });
                return;
              }
              const stillRunningPrintProcesses = await props.atlasEngineClient.processInstances.query({
                processModelId: ProcessConstants['Papiere drucken'].processId,
                correlationId: pau.kundenauftrag.process_correlation_id,
              });
              if (stillRunningPrintProcesses.totalCount === 0) {
                await props.atlasEngineClient.processModels.startProcessInstance({
                  processModelId: ProcessConstants['Papiere drucken'].processId,
                  correlationId: pau.kundenauftrag.process_correlation_id,
                  initialToken: {
                    produktionsauftrag_database_id: pau.database_id,
                    kundenauftrag_database_id: pau.kundenauftrag.database_id,
                  },
                });
              }
              setCorrelationId(pau.kundenauftrag.process_correlation_id!);
              setNummerEntered(true);
            } catch {
              setValidationError({
                detail: [
                  {
                    loc: ['pau_nummer'],
                    msg: 'PAU existiert nicht',
                    type: 'value_error',
                  },
                ],
              });
            }
          }}
        >Nachdrucken
        </Button>
        <Button onClick={() => props.onModalFinished()}>Schließen</Button>
      </DialogFooter>
    </Dialog>
  );
}
